import React from "react";
import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";

const ServicesOneABout = () => {
  const services = [
    {
      id: "1",
      img: "img/services/service1.png",
      title: "Chiropractic Therapy",
      text: "Chiropractic is a health care profession. Chiropractors perform adjustments (manipulations) to the spine or other parts of the body. The goal is to correct alignment problems, ease pain, and support the body's natural ability to heal itself.",
    },
    {
      id: "2",
      img: "img/services/service2.png",
      title: "Vertebral Subluxation",
      text: "Vertebral subluxation can be referred to as a partial dislocation of a spinal vertebra from its normal position causing pain, muscle tenderness, inflammation and could eventually result in a decrease of spinal mobility.",
    },
    {
      id: "3",
      img: "img/services/service3.png",
      title: "Radiculopathy & Paraesthesia",
      text: "Paraesthesia is an abnormal sensation, typically tingling or prickling, caused chiefly by pressure on or damage to peripheral nerves. It is commonly referred to as pins and needles.",
    },
    {
      id: "4",
      img: "img/services/service4.png",
      title: "Wellness Care",
      text: "Health as being - a state of complete physical, mental, and social well-being, and not merely the absence of disease or infirmity is the ultimate goal of chiropractic care.",
    },
    {
      id: "5",
      img: "img/services/service4.png",
      title: "Rehabilitation Therapy",
      text: "At Wellness Chiropractic, we offer rehabilitative services in conjunction with spinal adjustments aiming to ease pain, improve range of movement and quality of life and prevent further injury or disability.",
    },
  ];
  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel mb-50 text-end">
                <div className="section-icon">
                  <img
                    className="section-back-icon back-icon-right"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text section-text-small pos-rel">
                  <h5>health care facility</h5>
                  <h1>
                    Would you rather stay at home than go into a health care
                    facility?
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="facalty-text mb-50">
                <p>
                  In wellness chiropractic consultants, our patient's overall
                  health, lifestyle habits, and any specific concerns are our
                  priority. we help them to maintain spinal health and overall
                  well-being.
                </p>
                <p style={{ paddingTop: 0 }}>
                  This services, benefits our clients in ensuring.
                </p>
                <div style={{ paddingLeft: 20 }}>
                  <ul>
                    <li>Improved spinal health.</li>
                    <li>Reduced pain and discomfort.</li>
                    <li>Improved immune function.</li>
                    <li>Enhanced sleep quality.</li>
                    <li>Lowered blood pressure.</li>
                    <li>Improved mental health.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((service, i) => (
              <HomeSIngleService key={i} service={service} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesOneABout;
